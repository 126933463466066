export const formatMonths = totalMonths => {
  if (isNaN(totalMonths) || totalMonths < 0) {
    return undefined
  }

  const years = Math.floor(totalMonths / 12)
  const months = totalMonths % 12

  if (years > 0 && months > 0) {
    return `${years} year${years > 1 ? "s" : ""} ${months} month${
      months > 1 ? "s" : ""
    }`
  } else if (years > 0) {
    return `${years} year${years > 1 ? "s" : ""}`
  } else if (months > 0) {
    return `${months} month${months > 1 ? "s" : ""}`
  } else {
    return "0 months"
  }
}
