import React, { useState, useEffect } from "react"
import SelectInput from "../inputs/SelectInput"
import styled from "styled-components"
import MonthsInput from "../inputs/MonthsInput"
import { decimalToPercentage } from "./InputRow"

const AddButton = styled.button`
  border: none;
  background: none;
  color: var(--clr-text-blue);

  text-align: center;
  font-family: var(--ff-secondary);
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  cursor: pointer;

  line-height: 40px;

  transition: 0.2s ease-in-out;

  &:hover {
    //color: var(--clr-tertiary-bright);
    //text-decoration: underline;
  }
`

const AddContainer = styled.div`
  display: flex;
  justify-content: right;
  margin-right: 1rem !important;
`

const roles = [
  {
    value: "Software Engineering",
    label: "Software Engineering",
  },
  {
    value: "Data",
    label: "Data",
  },
  {
    value: "Cloud & Devops",
    label: "Cloud & Devops",
  },
  {
    value: "Test Automation",
    label: "Test Automation",
  },
  {
    value: "Business Agility",
    label: "Business Agility",
  },
  {
    value: "Design",
    label: "Design",
  },
]

const seniorities = [
  {
    value: "senior",
    label: "Senior",
  },
  {
    value: "lead",
    label: "Lead",
  },
]

// eslint-disable-next-line no-unused-vars
const durations = [
  {
    value: 1,
    label: "1 Month",
  },
  {
    value: 4,
    label: "4 Months",
  },
  {
    value: 8,
    label: "8 Months",
  },
  {
    value: 12,
    label: "1 Year",
  },
  {
    value: 16,
    label: "1 Year 4 Months",
  },
  {
    value: 20,
    label: "1 Year 8 Months",
  },
]

const workloads = [
  {
    value: 0.2,
    label: "20%",
  },
  {
    value: 0.4,
    label: "40%",
  },
  {
    value: 0.6,
    label: "60%",
  },
  {
    value: 0.8,
    label: "80%",
  },
  {
    value: 1,
    label: "100%",
  },
  {
    value: 1.2,
    label: "120%",
  },
  {
    value: 1.4,
    label: "140%",
  },
]

const InputRowMobile = ({ values, members, setMembers, index }) => {
  const [role, setRole] = useState(
    values?.role ? roles.find(role => role.value === values.role) : null
  )

  const [seniority, setSeniority] = useState(
    values?.seniority
      ? seniorities.find(seniority => seniority.value === values.seniority)
      : null
  )

  const [duration, setDuration] = useState(values?.duration || null)

  const [workload, setWorkload] = useState(
    values?.workload
      ? {
          value: values.workload,
          label: decimalToPercentage(values.workload),
        }
      : {
          value: 1,
          label: "100%",
        }
  )

  const [touched, setTouched] = useState()
  const [roleError, setRoleError] = useState(false)
  const [seniorityError, setSeniorityError] = useState(false)

  useEffect(() => {
    setRole(
      values?.role ? roles.find(role => role.value === values.role) : null
    )
  }, [values?.role])

  useEffect(() => {
    setSeniority(
      values?.seniority
        ? seniorities.find(seniority => seniority.value === values.seniority)
        : null
    )
  }, [values?.seniority])

  useEffect(() => {
    setDuration(values?.duration || null)
  }, [values?.duration])

  useEffect(() => {
    setWorkload(
      values?.workload
        ? {
            value: values.workload,
            label: decimalToPercentage(values.workload),
          }
        : {
            value: 1,
            label: "100%",
          }
    )
  }, [values?.workload])

  const handleAdd = () => {
    if (!role?.value) {
      setRoleError(true)
    }
    if (!seniority?.value) {
      setSeniorityError(true)
    }

    if (!duration) {
      setTouched(true)
    }

    if (!role?.value || !seniority?.value || !duration) {
      return
    }

    const newMember = {
      role: role.value,
      seniority: seniority.value,
      duration: duration,
      workload: workload?.value || 1,
    }
    setMembers([...members, newMember])
    setRole(null)
    setSeniority(null)
    setDuration("")

    setTouched(false)
  }

  const handleDelete = index => {
    const membersCopy = [...members]
    membersCopy.splice(index, 1)
    setMembers(membersCopy)
  }

  return (
    <>
      <div>
        <SelectInput
          options={roles}
          value={role}
          setValue={setRole}
          members={members}
          setMembers={setMembers}
          index={index}
          label="role"
          error={roleError}
          setError={setRoleError}
        />
      </div>
      <div>
        <SelectInput
          options={seniorities}
          value={seniority}
          setValue={setSeniority}
          members={members}
          setMembers={setMembers}
          index={index}
          label="seniority"
          error={seniorityError}
          setError={setSeniorityError}
        />
      </div>
      <div>
        <MonthsInput
          value={duration}
          setValue={setDuration}
          members={members}
          setMembers={setMembers}
          index={index}
          label="duration"
          touched={touched}
          setTouched={setTouched}
        />
      </div>
      <div>
        <SelectInput
          options={workloads}
          value={workload}
          setValue={setWorkload}
          members={members}
          setMembers={setMembers}
          index={index}
          label="workload"
        />
      </div>
      {!values && (
        <AddContainer>
          <AddButton onClick={handleAdd}>ADD</AddButton>
        </AddContainer>
      )}
      {values && (
        <AddContainer>
          <AddButton
            style={{ color: "#E00038" }}
            onClick={() => handleDelete(index)}
          >
            DELETE
          </AddButton>
        </AddContainer>
      )}
    </>
  )
}

export default InputRowMobile
