import React from "react"
import styled, { keyframes } from "styled-components"
import TextInput from "../inputs/TextInput"
import ServicesArrow from "../../../assets/svgs/services_arrow.svg"
import LoadingMessage from "./LoadingMessage"
import { Paragraph } from "../Paragraph"
import MobileServiceCarousel from "./MobileServiceCarousel"

const flyOut = keyframes`
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateX(100px);
  }
`

const flyIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-100px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`

const DesktopContainer = styled.div`
  position: relative;
  width: 800px;
  height: 320px;
  margin: 3rem auto;
  margin-bottom: 5.5rem;
  overflow: visible;

  @media (max-width: 39.999em) {
    display: none;
  }

  @media (max-width: 920px) and (min-width: 40em) {
    transform: scale(0.8);
    transform-origin: left;
    margin-left: 1.25rem;
  }

  @media (max-width: 920px) and (min-width: 840px) {
    transform-origin: center;
  }
`

const InputSubmitContainer = styled.div`
  display: flex;
  gap: 2rem;
  margin-inline: auto;
  margin-top: 2rem;

  @media (min-width: 40em) {
    max-width: calc(100% - 2.5rem);
  }

  @media (min-width: 840px) {
    max-width: 800px;
  }
`

const ServiceContainer = styled.div`
  position: absolute;
  left: ${props => `${props.left}px`};
  top: ${props => `${props.top}px`};

  background-color: ${props => props.color};
  color: ${props => props.colorText};
  border-radius: 20px;
  cursor: pointer;
  width: ${props => props.width};
  opacity: 0; /* Start with invisible */
  animation: ${props => (props.isLoading ? flyOut : flyIn)} forwards;
  animation-timing-function: ease;

  animation-duration: ${props => (props.isLoading ? "0.2s" : "0.4s")};

  font-family: var(--ff-primary);
  font-weight: 600;
  font-size: 1rem;
  text-align: left;
  padding: 1.5rem;

  &:hover {
    filter: brightness(95%);
    top: ${props => `${props.top - 5}px`};
    left: ${props => `${props.left + 1}px`};
  }
`

const StyledServicesArrow = styled(ServicesArrow)`
  top: -18px;
  transform: rotate(180deg);
  position: absolute;
`

const DesktopSubmitButton = styled.button`
  display: none;
  border: 0;
  border-radius: 0px;
  font-family: var(--ff-secondary);
  font-weight: 700;
  font-size: 1em;
  letter-spacing: 0.0046em;
  color: white;

  width: 200px;

  background-color: var(--clr-primary);
  cursor: pointer;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  position: relative;
  transition: 0.2s ease-in-out;

  &:hover {
    background-color: var(--clr-tertiary-bright);
    color: var(--clr-text-blue);
  }

  @media (min-width: 40rem) {
    width: 220px;
    height: 54px;
    display: inline-block;
  }
`

const MobileSubmitButton = styled.button`
  display: block;
  border: 0;
  border-radius: 0px;
  font-family: var(--ff-secondary);
  font-weight: 700;
  font-size: 1em;
  line-height: 46px;
  letter-spacing: 0.0046em;
  color: white;

  margin-left: auto;
  margin-right: auto;
  margin-bottom: 5.5rem;

  width: 200px;

  background-color: var(--clr-primary);
  cursor: pointer;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;

  transition: 0.2s ease-in-out;

  @media (min-width: 40rem) {
    display: none;
  }
`

const examplePrompts = [
  "Two developers for 12 months on a full-time basis.",
  "Website revamp with an emphasis on design.",
  "Custom ERP application for small business use.",
  "Migration of legacy systems to a cloud-based infrastructure.",
]

const TeamBuilder = ({
  project,
  setProject,
  getTeam,
  statements,
  isLoading,
  captchaError,
  setTokenV2,
}) => {
  const handleClick = item => {
    if (statements) {
      setProject(project + "\n" + item)
    } else {
      setProject(item)
    }
  }

  const content = statements ? statements : examplePrompts

  return (
    <>
      <Paragraph>
        Please describe your project needs and requirements, and our AI-powered
        tool will help you evaluate the potential team and estimate a detailed
        cost breakdown. Additionally, our AI tool will assist you in refining
        your project objectives by proposing various statements. The more
        specific you are, the better answers you will get. You can use any
        language (English, Finnish etc.) you want.
      </Paragraph>
      <InputSubmitContainer>
        <TextInput project={project} setProject={setProject} />
        <DesktopSubmitButton onClick={getTeam}>
          assemble team
        </DesktopSubmitButton>
      </InputSubmitContainer>
      <DesktopContainer>
        {isLoading && (
          <LoadingMessage
            isLoading={isLoading}
            captchaError={captchaError}
            setTokenV2={setTokenV2}
          />
        )}
        <ServiceContainer
          color="#C6C4FD"
          colorText="#0B0576"
          width="330px"
          left={0}
          top={24}
          onClick={() => handleClick(content[0])}
          isLoading={isLoading}
        >
          {content[0]}
          <StyledServicesArrow fill="#C6C4FD" />
        </ServiceContainer>
        <ServiceContainer
          color="#B1FCED"
          colorText="#004235"
          width="330px"
          left={405}
          top={0}
          onClick={() => handleClick(content[1])}
          isLoading={isLoading}
        >
          {content[1]}
          <StyledServicesArrow fill="#B1FCED" />
        </ServiceContainer>
        <ServiceContainer
          color="#FFC2D1"
          colorText="#9d0042"
          width="330px"
          left={112}
          top={174}
          onClick={() => handleClick(content[2])}
          isLoading={isLoading}
        >
          {content[2]}
          <StyledServicesArrow fill="#FFC2D1" />
        </ServiceContainer>
        <ServiceContainer
          color="#CAE5FF"
          colorText="#002f43"
          width="330px"
          left={505}
          top={160}
          onClick={() => handleClick(content[3])}
          isLoading={isLoading}
        >
          {content[3]}
          <StyledServicesArrow fill="#CAE5FF" />
        </ServiceContainer>
      </DesktopContainer>

      <MobileServiceCarousel
        content={content}
        handleClick={handleClick}
        isLoading={isLoading}
        captchaError={captchaError}
        setTokenV2={setTokenV2}
      />

      <MobileSubmitButton onClick={getTeam}>assemble team</MobileSubmitButton>
    </>
  )
}

export default TeamBuilder
