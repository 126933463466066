import React, { useState, useCallback, useEffect } from "react"
import Layout from "../../components/common/Layout"
import Main from "../../components/common/Main"
import Meta from "../../components/common/Meta"
import { FindingRightConsultant } from "../../components/common/downloadSection/FindingRightConsultant"
import ContactBox from "../../components/common/formboxes/ContactBox"
import PageIntro from "../../components/common/intro/PageIntro"
import InfoBoxes from "../../components/common/infoboxes/InfoBoxes"
import PriceCalculator from "../../components/our-pricing/PriceCalculator/PriceCalculator"
import PricingNavigationBar from "../../components/our-pricing/PricingNavigationBar"
import TeamBuilder from "../../components/our-pricing/TeamBuilder/TeamBuilder"
import { useGoogleReCaptcha } from "react-google-recaptcha-v3"
import PricingMessage from "../../components/our-pricing/PricingMessage"
import PriceCalculatorMobile from "../../components/our-pricing/PriceCalculator/PriceCalculatorMobile"
import { formatMonths } from "../../utils/formatMonths"
import styled from "styled-components"

const AutoScroll = styled.div`
  scroll-margin-block-start: 150px;

  @media (max-width: 40em) {
    scroll-margin-block-start: 100px;
  }
`

export const Head = () => (
  <Meta
    title="Rebase Pricing - Affordable Dev, Cloud, QA, and Agile Services"
    description="View Rebase's competitive pricing for custom software development, cloud services, data engineering, test automation, business agility and more."
  />
)

const Calculator = () => {
  const [showCalc, setShowCalc] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(false)
  const [prefilled, setPrefilled] = useState()
  const [project, setProject] = useState()
  const [members, setMembers] = useState([])
  const [tokenV2, setTokenV2] = useState()
  const [tokenV3, setTokenV3] = useState()
  const [captchaError, setCaptchaError] = useState(false)

  const [statements, setStatements] = useState()
  const [summary, setSummary] = useState("")

  const title = [["Discover", "Our Prices"]]

  const { executeRecaptcha } = useGoogleReCaptcha()

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log("Execute recaptcha not yet available")
      return
    }
    const token = await executeRecaptcha("submit")
    setTokenV3(token)
  }, [executeRecaptcha, setTokenV3])

  const fetchTeamData = useCallback(
    async project => {
      console.log("fetchign team data")
      setCaptchaError(false)

      try {
        const response = await fetch(process.env.GATSBY_GET_TEAM, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "x-api-key": process.env.GATSBY_GET_TEAM_KEY,
          },
          body: JSON.stringify({ project, tokenV2, tokenV3 }),
        })

        if (response.error === "Invalid Validation") {
          console.log("Set captcaherror True")
          setCaptchaError(true)
          return 403
        } else if (response.status === 403) {
          throw new Error("Forbidden")
        }

        const responseJson = await response.json()
        if (!response.ok || !responseJson.ok) {
          throw new Error("Network response was not ok")
        }
        return responseJson
      } catch (error) {
        console.error("Failed to fetch team data:", error)
      }
    },
    [tokenV2, tokenV3]
  )

  const getTeam = async () => {
    if (isLoading && !captchaError) {
      return
    }

    if (project) {
      setPrefilled("")
      setError(false)
      setIsLoading(true)
      setShowCalc(true)

      await handleReCaptchaVerify()

      const minimumLoadingTime = new Promise(resolve =>
        setTimeout(resolve, 2440)
      )

      let captcha = false
      try {
        const fetchPromise = fetchTeamData(project)
        const [response] = await Promise.all([fetchPromise, minimumLoadingTime])

        if (response === 403) {
          console.log("Captcha error")
          captcha = true
        } else {
          setSummary(response.team.summary)

          if (typeof response.team.duration.months !== "number") {
            throw new Error("Invalid duration format")
          }

          const validRoles = [
            "Software Engineering",
            "Data",
            "Cloud & Devops",
            "Test Automation",
            "Business Agility",
            "Design",
          ]

          const validSeniority = ["senior", "lead"]

          const calcMembers = response.team.team.reduce((acc, member) => {
            // Validate each member
            if (
              validRoles.includes(member.role) &&
              validSeniority.includes(member.seniority) &&
              typeof member.allocation === "number" &&
              member.allocation >= 0 &&
              member.allocation <= 1.4
            ) {
              acc.push({
                duration: formatMonths(response.team.duration.months),
                role: member.role,
                seniority: member.seniority,
                workload: member.allocation,
              })
            }
            return acc
          }, [])

          // Check if any members passed validation
          if (calcMembers.length === 0) {
            throw new Error("No valid members found")
          }

          setMembers(calcMembers)
          setStatements(response.improvedDescriptions.clarifying_statements)

          setIsLoading(false)
          const element = document.getElementById("calculator")
          setTimeout(() => {
            element.scrollIntoView({ behavior: "smooth", block: "start" })
          }, 400)
        }
      } catch (error) {
        console.error("Failed to fetch data:", error)
        setError(true)
        if (!captcha) {
          //Stay in loadign view is captcha error
          setIsLoading(false)
        }
        setMembers([])
      }
    }
  }

  useEffect(() => {
    handleReCaptchaVerify()
  }, [handleReCaptchaVerify])

  useEffect(() => {
    console.log(tokenV2)
    getTeam()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenV2])

  return (
    <Layout>
      <Main>
        <PageIntro title={title} subheading="" />
        <PricingNavigationBar />

        <TeamBuilder
          project={project}
          setProject={setProject}
          getTeam={getTeam}
          statements={statements}
          isLoading={isLoading}
          captchaError={captchaError}
          setTokenV2={setTokenV2}
        />

        <AutoScroll id="calculator"></AutoScroll>
        {!isLoading && showCalc && (
          <PricingMessage error={error} summary={summary} />
        )}

        {!isLoading && showCalc && (
          <PriceCalculator
            setPrefilled={setPrefilled}
            members={members}
            setMembers={setMembers}
          />
        )}
        {!isLoading && showCalc && (
          <PriceCalculatorMobile
            setPrefilled={setPrefilled}
            members={members}
            setMembers={setMembers}
          />
        )}

        <FindingRightConsultant dark />
        <InfoBoxes
          content={[
            { number: 7.8, extra: "", content: "MEUR Net sales (2023)" },
            { number: 11, extra: "%", content: "EBITA (2023)" },
            { number: 65, extra: "%", content: "Growth (2023)" },
          ]}
        />
      </Main>
      <ContactBox prefilled={prefilled} />
    </Layout>
  )
}

export default Calculator
