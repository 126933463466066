import React from "react"
import styled from "styled-components"
import InputRow from "./InputRow"
import usePriceCalculator from "../../../hooks/usePriceCalculator"
import SecondaryTitle from "../../common/SecondaryTitle"
import ContactButton from "./ContactButton"

const PricingTableSection = styled.section`
  margin-bottom: 4rem;
  display: none;

  width: calc(100% - 2.5rem);
  margin-inline: auto;

  @media (min-width: 64em) {
    margin-bottom: 7.5rem;
    margin-top: 2rem;
  }

  @media (min-width: 40em) {
    display: block;
  }
`

const Table = styled.table`
  max-width: 1000px;
  width: 100%;
  margin-inline: auto;
  min-width: 280px;
  border-collapse: collapse;
  z-index: 2;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  table-layout: fixed;
  font-family: var(--ff-primary);
  @media (min-width: 40em) {
  }

  thead {
    height: 48px;
    font-family: var(--ff-secondary);
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 28px;
    color: var(--clr-text-blue);
    border-bottom: 4px solid #e00038;
    th {
      text-align: center;
    }

    @media (min-width: 40em) {
      height: 75px;
      border-bottom: 5px solid #e00038;
      font-size: 1.5rem;
      line-height: 23px;
    }
  }
  tbody {
    width: 100%;
    td,
    thead th {
      position: relative;
    }
    tr:last-child {
    }
  }
  .border-bottom {
  }
  tr {
    height: 48px;
    overflow: visible;
    @media (min-width: 40em) {
      height: 75px;
    }
  }
  td {
    text-align: center;
    overflow: visible;

    font-family: var(--ff-primary);
    font-style: normal;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 16px;

    &:first-child {
      font-style: normal;
      font-weight: 700;
    }

    tr:last-child td {
      background: transparent;
      border: 0px;
    }

    @media (min-width: 40em) {
      font-size: 1.5rem;
      line-height: 28px;
    }
  }
`
const TotalConrainer = styled.div`
  max-width: 1000px;
  margin-inline: auto;
  margin-top: 0.47rem;
`

const TotalWrapper = styled.div`
  border-top: 3px solid #e00038;
  max-width: 400px;
  margin-right: 0;
  margin-left: auto;
  display: flex;
  justify-content: right;
`

const TotalLabel = styled.div`
  font-family: var(--ff-secondary);
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 28px;
  color: var(--clr-text-blue);
  text-align: center;

  display: flex;
  width: 200px;
  justify-content: center;
  align-items: center;

  @media (min-width: 40em) {
    font-size: 1.5rem;
    line-height: 23px;
  }
`

const TotalPrice = styled(SecondaryTitle)`
  max-width: 200px;
  margin: 0;
  font-family: var(--ff-primary);
  font-weight: 600;
  font-size: 2rem;
`

const PlaceHolder = styled.div`
  color: #b9b9b9;
  font-family: var(--ff-primary);
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  line-height: 4.5rem;
`

const PriceCalculator = ({ setPrefilled, members, setMembers }) => {
  const prices = usePriceCalculator(members)

  // eslint-disable-next-line no-unused-vars
  const generateMessage = (consultants, _prices) => {
    if (consultants.length == 0) {
      return ""
    }

    let message =
      "We are interested in assembling a team of consultants with the following specifications:\n\n"

    consultants.forEach((consultant, index) => {
      const { role, seniority, duration, workload } = consultant
      //const price = prices[index]
      message += `${index + 1}. ${role}, ${seniority}, ${duration}, Workload: ${
        workload * 100
      }%\n`
    })

    message += "\nPlease contact us to discuss further."
    return message
  }

  const scrollToContact = () => {
    const contactSection = document.getElementById("contact")
    if (contactSection) {
      contactSection.scrollIntoView({ behavior: "smooth" })
    }
  }

  const contactHandler = () => {
    setPrefilled(generateMessage(members, prices))
    scrollToContact()
  }

  return (
    <>
      <PricingTableSection>
        <Table>
          <thead>
            <tr>
              <th>Role</th>
              <th>Senior/Lead</th>
              <th>Duration</th>
              <th>Workload</th>
              <th>Price</th>
            </tr>
          </thead>
          <tbody>
            {members.map((member, i) => {
              return (
                <InputRow
                  key={i}
                  values={member}
                  members={members}
                  setMembers={setMembers}
                  price={prices[i]}
                  index={i}
                />
              )
            })}
            <InputRow members={members} setMembers={setMembers} />
          </tbody>
        </Table>
        <TotalConrainer>
          <TotalWrapper>
            {members.length > 0 && (
              <>
                <TotalLabel>Total</TotalLabel>
                <TotalPrice>
                  {Math.floor(
                    prices.reduce((accumulator, currentValue) => {
                      return accumulator + currentValue
                    }, 0)
                  )
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, " ")}{" "}
                  €
                </TotalPrice>
              </>
            )}
            {members.length == 0 && (
              <PlaceHolder>Add team members to see the pricing</PlaceHolder>
            )}
          </TotalWrapper>
        </TotalConrainer>
        <ContactButton onClick={contactHandler} />
      </PricingTableSection>
    </>
  )
}

export default PriceCalculator
