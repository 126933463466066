import { useState, useEffect } from "react"

const pricingTable = {
  "Software Engineering": {
    senior: 95,
    lead: 110,
  },
  Data: {
    senior: 95,
    lead: 115,
  },
  "Cloud & Devops": {
    senior: 95,
    lead: 115,
  },
  "Test Automation": {
    senior: 85,
    lead: 95,
  },
  "Business Agility": {
    senior: 95,
    lead: 120,
  },
  Design: {
    senior: 95,
    lead: 110,
  },
}

const getRate = (expertise, seniority) => {
  const seniorityKey = seniority.toLowerCase()

  if (pricingTable[expertise]) {
    if (pricingTable[expertise][seniorityKey]) {
      return pricingTable[expertise][seniorityKey]
    } else {
      return "Seniority level not found."
    }
  } else {
    return "Expertise not found."
  }
}

const getPrice = (role, seniority, duration, workload) => {
  const rate = getRate(role, seniority)

  const price = rate * 157.5 * workload * duration
  return price
}

export const parseMonthsFromString = inputString => {
  if (!inputString || typeof inputString !== "string") {
    return undefined
  }

  const yearsRegex = /(\d+)\syear/
  const monthsRegex = /(\d+)\smonth/

  const yearsMatch = inputString.match(yearsRegex)
  const monthsMatch = inputString.match(monthsRegex)

  const years = yearsMatch ? parseInt(yearsMatch[1], 10) : 0
  const months = monthsMatch ? parseInt(monthsMatch[1], 10) : 0

  return years * 12 + months
}

const usePriceCalculator = members => {
  const [prices, setPrices] = useState([])

  useEffect(() => {
    setPrices([])

    members.forEach(member => {
      const price = getPrice(
        member.role,
        member.seniority,
        parseMonthsFromString(member.duration),
        member.workload
      )
      setPrices(prices => [...prices, price])
    })
  }, [members])

  return prices
}

export default usePriceCalculator
