import React, { useState } from "react"
import styled from "styled-components"
import usePriceCalculator from "../../../hooks/usePriceCalculator"
import SecondaryTitle from "../../common/SecondaryTitle"
import ContactButton from "./ContactButton"
import InputRowMobile from "./InputRowMobile"
import Arrow from "../../../assets/svgs/chevron.svg"

const StyledArrow = styled(Arrow)`
  margin-top: 5px;
  height: 12px;
`

const PricingTableSection = styled.section`
  margin-top: 5.5rem;
  margin-bottom: 4rem;

  width: calc(100% - 2.5rem);
  margin-inline: auto;

  @media (min-width: 40em) {
    display: none;
  }
`

const PriceCalculator = styled.div`
  display: flex;
  gap: 0rem;
  flex-direction: column;
  max-width: 1000px;
  width: 100%;
  margin-inline: auto;
  min-width: 280px;
  border-collapse: collapse;
  z-index: 2;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  table-layout: fixed;
  font-family: var(--ff-primary);

  @media (min-width: 40em) {
  }
`

const Header = styled.div`
  font-family: var(--ff-secondary);
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 28px;
  color: var(--clr-text-blue);

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  gap: 5px;

  margin-right: 3rem;

  > * {
    height: 40px;
    display: flex;
    align-items: center;
  }
`

const Body = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: 100%;

  & * {
    margin: 0;
    .css-12ovsvr-container {
      margin: 0;
      height: 40px;
    }
  }
`

const Entry = styled.div`
  display: flex;
  flex-direction: column;
  //border-bottom: 3px solid #e00038;
  overflow: hidden;
  .closed {
    height: 0px;
    margin-bottom: 0rem;
    overflow: hidden;
    margin-top: 0;
  }

  .closedArrow {
    transform: rotate(-90deg);
  }
`

const TotalConrainer = styled.div`
  max-width: 1000px;
  margin-inline: auto;
  margin-top: 0.47rem;
`

const TotalWrapper = styled.div`
  margin-right: 0;
  margin-left: auto;
  display: flex;
  justify-content: space-between;
`

const TotalLabel = styled.div`
  font-size: 1rem;
  line-height: 28px;
  color: var(--clr-text-blue);
  text-align: center;

  font-family: var(--ff-secondary);
  font-weight: 800;

  min-width: 75.2px;
  margin-right: 3rem;

  display: flex;
  justify-content: left;
  align-items: center;

  @media (min-width: 40em) {
    font-size: 1.5rem;
    line-height: 23px;
  }
`

const TotalPrice = styled(SecondaryTitle)`
  width: 100%;
  margin: 0;
  font-family: var(--ff-primary);
  font-weight: 600;
  font-size: 1.25rem;
  text-align: right;
  margin-right: 0rem;
`

const PlaceHolder = styled.div`
  color: #b9b9b9;
  font-family: var(--ff-primary);
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 3rem;

  margin-inline: auto;
`

const MemberTitle = styled.div`
  font-size: 1rem;
  color: var(--clr-text-blue);
  font-family: var(--ff-secondary);
  font-weight: 800;
  margin-bottom: 1rem;
  display: flex;
  justify-content: end;
  gap: 2rem;
`

const EntryContentContainer = styled.div`
  display: flex;
  margin-bottom: 2rem;
  margin-top: 1rem;

  padding-right: 1px;
`

const PriceCalculatorMobile = ({ setPrefilled, members, setMembers }) => {
  const prices = usePriceCalculator(members)
  const [openEntries, setOpenEntries] = useState({})

  const toggleEntry = index => {
    setOpenEntries(prevState => ({
      ...prevState,
      [index]: !prevState[index],
    }))
  }

  // eslint-disable-next-line no-unused-vars
  const generateMessage = (consultants, _prices) => {
    if (consultants.length == 0) {
      return ""
    }

    let message =
      "We are interested in assembling a team of consultants with the following specifications:\n\n"

    consultants.forEach((consultant, index) => {
      const { role, seniority, duration, workload } = consultant
      //const price = prices[index]
      message += `${index + 1}. ${role}, ${seniority}, ${duration}, Workload: ${
        workload * 100
      }%\n`
    })

    message += "\nPlease contact us to discuss further."
    return message
  }

  const scrollToContact = () => {
    const contactSection = document.getElementById("contact")
    if (contactSection) {
      contactSection.scrollIntoView({ behavior: "smooth" })
    }
  }

  const contactHandler = () => {
    setPrefilled(generateMessage(members, prices))
    scrollToContact()
  }

  return (
    <PricingTableSection>
      <PriceCalculator>
        {members.map((member, i) => {
          return (
            <Entry key={i}>
              <MemberTitle onClick={() => toggleEntry(i)}>
                <div style={{ marginRight: "auto " }}>{member.role}</div>
                <div>
                  {Math.floor(prices[i])
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, " ")}{" "}
                  €
                </div>
                <StyledArrow className={openEntries[i] ? "" : "closedArrow"} />
              </MemberTitle>
              <EntryContentContainer className={openEntries[i] ? "" : "closed"}>
                <Header>
                  <div>Role</div>
                  <div>Senior/Lead</div>
                  <div>Duration</div>
                  <div>Workload</div>
                  <div></div>
                </Header>
                <Body>
                  <InputRowMobile
                    values={member}
                    members={members}
                    setMembers={setMembers}
                    price={prices[i]}
                    index={i}
                  />
                </Body>
              </EntryContentContainer>
            </Entry>
          )
        })}
        <Entry>
          <MemberTitle onClick={() => toggleEntry(members.length + 1)}>
            <div style={{ marginRight: "auto " }}>Add Team Member</div>
            <StyledArrow
              className={openEntries[members.length + 1] ? "closedArrow" : ""}
            />
          </MemberTitle>
          <EntryContentContainer
            className={openEntries[members.length + 1] ? "closed" : ""}
          >
            <Header>
              <div>Role</div>
              <div>Senior/Lead</div>
              <div>Duration</div>
              <div>Workload</div>
              <div></div>
            </Header>
            <Body>
              <InputRowMobile members={members} setMembers={setMembers} />
            </Body>
          </EntryContentContainer>
        </Entry>
      </PriceCalculator>
      <TotalConrainer>
        <TotalWrapper>
          {members.length > 0 && (
            <>
              <TotalLabel>Total</TotalLabel>
              <TotalPrice>
                {Math.floor(
                  prices.reduce((accumulator, currentValue) => {
                    return accumulator + currentValue
                  }, 0)
                )
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, " ")}{" "}
                €
              </TotalPrice>
            </>
          )}
          {members.length == 0 && (
            <PlaceHolder>Add team members to see the pricing</PlaceHolder>
          )}
        </TotalWrapper>
      </TotalConrainer>
      <ContactButton onClick={contactHandler} />
    </PricingTableSection>
  )
}

export default PriceCalculatorMobile
