import React from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import { Pagination } from "swiper"
import "swiper/css" // import Swiper styles
import "swiper/css/pagination"
import "swiper/css/navigation"
import styled, { keyframes } from "styled-components"
import ServicesArrow from "../../../assets/svgs/services_arrow.svg"
import LoadingMessage from "./LoadingMessage"

const flyOut = keyframes`
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateX(100px);
  }
`

const flyIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-100px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`

const StyledSwiper = styled(Swiper)`
  .swiper-pagination {
    position: absolute;
    top: 0px;
    padding: 0;
    bottom: auto;
    margin-top: 0;
    width: 100%;
    justify-content: center;

    display: ${props => (props.isLoading ? "none" : "flex")};
  }
  display: ${props => (props.isLoading ? "none" : "flex")};

  opacity: 0; /* Start with invisible */
  animation: ${props => (props.isLoading ? flyOut : flyIn)} forwards;
  animation-timing-function: ease;

  animation-duration: ${props => (props.isLoading ? "0.2s" : "0.4s")};
`

const MobileContainer = styled.div`
  width: 100%; // Adjust width for mobile view
  margin: 3rem auto;
  margin-top: 1rem;
  overflow: visible;

  @media (min-width: 40em) {
    display: none;
  }

  .swiper {
    max-width: calc(100% - 2.5rem);
    padding: 0;
    padding-top: 27px;
  }
`

const ServiceContainer = styled.div`
  background-color: ${props => props.color};
  color: ${props => props.colorText};
  border-radius: 20px;
  cursor: pointer;
  width: 100%; // Adjust width for the Swiper
  font-family: var(--ff-primary);
  font-weight: 600;
  font-size: 0.9375rem;
  text-align: left;
  padding: 1.5rem;

  margin-inline: auto;
`

const StyledServicesArrow = styled(ServicesArrow)`
  position: absolute;
  top: -18px;
  transform: rotate(180deg);
`

const MobileServiceCarousel = ({
  content,
  handleClick,
  isLoading,
  captchaError,
  setTokenV2,
}) => {
  return (
    <MobileContainer>
      {isLoading && (
        <LoadingMessage
          isLoading={isLoading}
          captchaError={captchaError}
          setTokenV2={setTokenV2}
        />
      )}
      <StyledSwiper
        spaceBetween={50}
        slidesPerView={1}
        pagination={true}
        modules={[Pagination]}
        loop={true}
        isLoading={isLoading}
      >
        <SwiperSlide>
          <ServiceContainer
            color="#C6C4FD"
            colorText="#0B0576"
            width="330px"
            left={100}
            top={24}
            onClick={() => handleClick(content[0])}
          >
            {content[0]}
            <StyledServicesArrow fill="#C6C4FD" />
          </ServiceContainer>
        </SwiperSlide>
        <SwiperSlide>
          <ServiceContainer
            color="#B1FCED"
            colorText="#004235"
            width="330px"
            left={505}
            top={0}
            onClick={() => handleClick(content[1])}
          >
            {content[1]}
            <StyledServicesArrow fill="#B1FCED" />
          </ServiceContainer>
        </SwiperSlide>
        <SwiperSlide>
          <ServiceContainer
            color="#FFC2D1"
            colorText="#9d0042"
            width="330px"
            left={212}
            top={174}
            onClick={() => handleClick(content[2])}
          >
            {content[2]}
            <StyledServicesArrow fill="#FFC2D1" />
          </ServiceContainer>
        </SwiperSlide>
        <SwiperSlide>
          <ServiceContainer
            color="#CAE5FF"
            colorText="#002f43"
            width="330px"
            left={605}
            top={160}
            onClick={() => handleClick(content[3])}
          >
            {content[3]}
            <StyledServicesArrow fill="#CAE5FF" />
          </ServiceContainer>
        </SwiperSlide>
      </StyledSwiper>
    </MobileContainer>
  )
}

export default MobileServiceCarousel
