import React from "react"
import styled from "styled-components"

const ContactButtonContainer = styled.section`
  margin-bottom: 2.5rem;
  margin-top: 2.5rem;

  margin-left: 1.25rem;
  margin-right: 1.25rem;

  display: flex;
  justify-content: center;

  @media (min-width: 40em) {
    text-align: center;
    margin-bottom: 7.5rem;
    margin-top: 3rem;
  }
`

const StyledContactLink = styled.button`
  display: inline-block;
  border: 0;
  border-radius: 0px;
  font-family: var(--ff-secondary);
  font-weight: 700;
  font-size: 1em;
  letter-spacing: 0.0046em;
  color: white;

  width: 200px;
  line-height: 49px;

  background-color: var(--clr-primary);
  cursor: pointer;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  position: relative;
  transition: 0.2s ease-in-out;

  &:hover {
    background-color: var(--clr-tertiary-bright);
    color: var(--clr-text-blue);
  }

  @media (min-width: 40rem) {
    width: 220px;
    line-height: 54px;
  }
`

const ContactButton = ({ className, onClick }) => {
  return (
    <ContactButtonContainer>
      <StyledContactLink
        className={className}
        onClick={onClick}
        title="Contact"
      >
        Let us contact you
      </StyledContactLink>
    </ContactButtonContainer>
  )
}

export default ContactButton
