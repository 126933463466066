import React, { useState } from "react"
import styled from "styled-components"

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 0;
  margin-inline: auto;

  @media (min-width: 40em) {
    max-width: 94%;
  }
`

const StyledInputField = styled.input`
  font-family: var(--ff-primary);
  font-weight: 400;
  font-size: 0.75rem;
  letter-spacing: 0.004em;

  height: 2.406rem;
  width: 100%;
  padding: 0.47rem;
  margin-top: 0.47rem;
  background: #ffffff;
  border: 0;
  box-shadow: rgb(122, 121, 121, 0.3) 0px 0px 0px 1px;

  color: var(--clr-text-black);

  @media (min-width: 40em) {
    font-size: 0.875rem;
    height: 2.781rem;
  }

  border: ${props => props.border};

  &:focus {
    outline: none;
  }
`

const MonthsInput = ({
  value,
  setValue,
  members,
  setMembers,
  index,
  label,
  touched,
  setTouched,
}) => {
  const [tempInput, setTempInput] = useState(undefined)

  const parseMonthsFromString = inputString => {
    if (!inputString || typeof inputString !== "string") {
      return undefined
    }

    const yearsRegex = /(\d+)\syear/
    const monthsRegex = /(\d+)\smonth/

    const yearsMatch = inputString.match(yearsRegex)
    const monthsMatch = inputString.match(monthsRegex)

    const years = yearsMatch ? parseInt(yearsMatch[1], 10) : 0
    const months = monthsMatch ? parseInt(monthsMatch[1], 10) : 0

    return years * 12 + months
  }

  function formatMonths(inputString) {
    let totalMonths = parseMonthsFromString(inputString)
    if (totalMonths == 0) {
      totalMonths = parseInt(inputString, 10)
    }

    if (isNaN(totalMonths) || totalMonths < 0) {
      return undefined
    }

    const years = Math.floor(totalMonths / 12)
    const months = totalMonths % 12

    if (years > 0 && months > 0) {
      return `${years} year${years > 1 ? "s" : ""} ${months} month${
        months > 1 ? "s" : ""
      }`
    } else if (years > 0) {
      return `${years} year${years > 1 ? "s" : ""}`
    } else if (months > 0) {
      return `${months} month${months > 1 ? "s" : ""}`
    } else {
      return "0 months"
    }
  }

  const handleChange = e => {
    const inputValue = e.target.value

    if (inputValue.length <= 3) {
      setValue(e.target.value)

      const updatedMember = {
        ...members[index],
        [label]: formatMonths(e.target.value),
      }

      const membersCopy = [...members]
      membersCopy[index] = updatedMember

      setMembers(membersCopy)
    }
  }

  const handleBlur = e => {
    if (e.target.value != "") {
      setValue(formatMonths(e.target.value))
    } else if (tempInput) {
      setValue(formatMonths(tempInput))
      setTempInput(undefined)
    }
    if (!touched) setTouched(true)
  }

  const handleFocus = event => {
    setTouched(false)
    setTempInput(event.target.value)
    setValue("")
  }

  // Determine if the input value is invalid
  const isValid = value && parseMonthsFromString(value) !== undefined

  return (
    <InputContainer>
      <StyledInputField
        type="text"
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
        placeholder="Months"
        border={touched && !isValid ? "1px solid var(--clr-error)" : undefined}
      />
    </InputContainer>
  )
}

export default MonthsInput
