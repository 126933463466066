import React from "react"
import styled from "styled-components"
import Select from "react-select"

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 0;
  margin-inline: auto;
  text-align: left;
  border: ${props => props.border};

  @media (min-width: 40em) {
    max-width: 94%;
  }
`

const customStyles = border => {
  return {
    control: provided => ({
      ...provided,
      border: "0",
      boxShadow: "rgb(122, 121, 121, 0.3) 0px 0px 0px 1px",
      borderRadius: "0",
      height: "2.406rem",
      "@media only screen and (min-width: 40em)": {
        ...provided["@media (min-width: 40em)"],
        height: "2.781rem",
      },
    }),
    menu: provided => ({
      ...provided,
      border: "0",
      boxShadow: "rgb(122, 121, 121, 0.3) 0px 0px 0px 1px",
      borderRadius: "0",
    }),
    option: (provided, state) => ({
      ...provided,
      fontFamily: "var(--ff-primary)",
      fontSize: "0.75rem",
      fontWeight: "400",
      letterSpacing: "0.004rem",
      color: state.isSelected ? "white" : "var(--clr-text-black)",
      "@media only screen and (min-width: 40em)": {
        ...provided["@media (min-width: 40em)"],
        fontSize: "0.875rem",
      },

      //backgroundColor: state.isSelected ? "#DEEBFF" : provided.backgroundColor,
      backgroundColor: state.isSelected
        ? "var(--clr-primary)"
        : provided.backgroundColor,
    }),
    singleValue: provided => ({
      ...provided,
      fontFamily: "var(--ff-primary)",
      fontSize: "0.75rem",
      fontWeight: "400",
      letterSpacing: "0.004rem",
      color: "var(--clr-text-black)",
      "@media only screen and (min-width: 40em)": {
        ...provided["@media (min-width: 40em)"],
        fontSize: "0.875rem",
      },
    }),
    container: provided => ({
      ...provided,
      marginTop: "0.47rem",
      border: border,
    }),
    placeholder: provided => ({
      ...provided,
      fontFamily: "var(--ff-primary)",
      fontSize: "0.75rem",
      fontWeight: "400",
      letterSpacing: "0.004rem",
      color: "#b9b9b9",
      "@media only screen and (min-width: 40em)": {
        ...provided["@media (min-width: 40em)"],
        fontSize: "0.875rem",
      },
    }),
  }
}

const SelectInput = ({
  options,
  value,
  setValue,
  members,
  setMembers,
  index,
  label,
  error,
  setError,
  ...props
}) => {
  const handleChange = option => {
    setValue(option)

    const updatedMember = {
      ...members[index],
      [label]: option.value,
    }

    const membersCopy = [...members]
    membersCopy[index] = updatedMember

    setMembers(membersCopy)

    if (setError) {
      setError(false)
    }
  }

  return (
    <InputContainer>
      <Select
        {...props}
        value={value}
        onChange={handleChange}
        options={options}
        styles={customStyles(error ? "1px solid var(--clr-error)" : undefined)}
        placeholder={label == "workload" ? "100%" : undefined}
        isSearchable={false}
      />
    </InputContainer>
  )
}

export default SelectInput
