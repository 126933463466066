import React from "react"
import styled from "styled-components"
import Textarea from "react-expanding-textarea"

const StyledTextArea = styled(Textarea)`
  font-family: var(--ff-primary);
  font-weight: 400;
  font-size: 0.875rem;
  letter-spacing: 0.004em;

  height: 2.406rem;
  width: 100%;
  max-width: calc(100% - 2.5rem);
  padding: 0.47rem;
  background: #ffffff;
  border: 0;
  box-shadow: rgb(122, 121, 121, 0.3) 0px 0px 0px 1px;
  margin: 1px;
  margin-inline: auto;

  color: var(--clr-text-black);

  @media (min-width: 40em) {
    font-size: 1rem;
    height: 3rem;
    padding: 0.875rem;
    max-width: none;
  }

  &:focus {
    outline: none;
  }
`

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 0;
  margin-inline: auto;

  @media (min-width: 40em) {
    max-width: 94%;
  }
`

const TextInput = ({ project, setProject }) => {
  const onChange = e => {
    setProject(e.target.value)
  }

  return (
    <InputContainer>
      <StyledTextArea
        type="text"
        value={project}
        onChange={onChange}
        placeholder="What do you want to build? Type here or pick one from below."
        rows="3"
      />
    </InputContainer>
  )
}

export default TextInput
