import React, { useState, useEffect } from "react"
import styled, { css, keyframes } from "styled-components"
import { CircularLoading } from "respinner"
import StyledReCaptcha from "../../common/form/StyledReCaptcha"

const fadeOut = keyframes`
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    display: none;
  }
`

const LoadingText = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  color: black;

  font-family: var(--ff-primary);
  font-size: 1.25rem;

  opacity: 1;
  min-width: 483px;

  margin-bottom: 10%;

  animation: ${props =>
    props.shouldFadeOut
      ? css`
          ${fadeOut} 1s ease forwards
        `
      : "none"};

  @media (max-width: 40em) {
    width: 290px;
    max-width: 100%;
    min-width: auto;
    font-size: 0.75rem;
    margin-bottom: 0;
  }
`

const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 320px;
  margin-top: 3rem;
  margin-bottom: 7.5rem;

  justify-content: start;
  gap: 2rem;
  width: 800px;
  margin-inline: auto;

  @media (max-width: 40em) {
    margin: 0;
    margin-inline: auto;
    height: auto;
    min-height: 97.5px;
    justify-content: center;

    width: 338px;
    max-width: calc(100% - 2.5rem);
  }
`

const LoadingContent = styled.div`
  display: flex;
  flex-direction: column;
`

const TextSpinner = styled.div`
  display: flex;
  gap: 2rem;

  @media (max-width: 40em) {
    flex-direction: column;
    align-items: center;
  }
`

const message = "our AI is analyzing the details."

const LoadingMessage = ({ isLoading, captchaError, setTokenV2 }) => {
  const [text, setText] = useState("")
  const [charIndex, setCharIndex] = useState(0)
  const [shouldFadeOut, setShouldFadeOut] = useState(false)

  useEffect(() => {
    if (isLoading) {
      setShouldFadeOut(false)

      if (charIndex < message.length) {
        setTimeout(() => {
          setText(text + message[charIndex])
          setCharIndex(charIndex + 1)
        }, 40)
      }
    }
  }, [charIndex, isLoading, text])

  useEffect(() => {
    if (!isLoading) {
      setShouldFadeOut(true)
      setCharIndex(0)
      setTimeout(() => setText(""), 1000)
    }
  }, [isLoading, setText])

  return (
    <LoadingContainer id="loadingMessage">
      <LoadingContent>
        <TextSpinner>
          <CircularLoading size={40} duration={1} stroke="#1008bd" />
          <LoadingText shouldFadeOut={shouldFadeOut}>
            Just a bit longer — {text}
          </LoadingText>
        </TextSpinner>
        {captchaError && (
          <StyledReCaptcha
            onChange={response => setTokenV2(response)}
            onErrored={e => console.error(e)}
          />
        )}
      </LoadingContent>
    </LoadingContainer>
  )
}

export default LoadingMessage
