import React from "react"
import styled from "styled-components"
import { Paragraph } from "./Paragraph"
import SecondaryTitle from "../common/SecondaryTitle"

const PricingMessageContainer = styled.div`
  margin-inline: auto;
  display: flex;
  justify-content: center;
  margin-bottom: 3rem;
  gap: 2rem;
  flex-direction: column;
`

function PricingMessage({ error, summary }) {
  const message = error
    ? "Oops! We couldn't assemble a team with that description. Remember, we're diving into IT projects here. Give it another whirl, or manually build your tech dream team."
    : summary

  return (
    <>
      <SecondaryTitle>Your Team</SecondaryTitle>
      <PricingMessageContainer>
        <Paragraph>{message}</Paragraph>
      </PricingMessageContainer>
    </>
  )
}

export default PricingMessage
